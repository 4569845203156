import { Component } from '@angular/core';
import { FilterOptions } from 'src/app/models/FilterOptions';
import { ServiceCatalogueItem } from 'src/app/models/ServiceCatalogueItem';
import { ServiceCatalogueService } from 'src/app/services/service-catalogue.service';
import { DropDownOption } from '../drop-down/drop-down.component';
import { Pinned } from 'src/app/models/Pinned';

class EnvironmentBoard {
  serviceId?: string;
  serviceName?: string;
  environment?: string;
  lastDeployed?: Date;
  branch?: string;
  version?: string;
}

@Component({
  selector: 'app-environment-boards',
  templateUrl: './environment-boards.component.html',
  styleUrls: ['./environment-boards.component.scss']
})
export class EnvironmentBoardsComponent {
  catalogueItems?: ServiceCatalogueItem[];

  dropDownProjects?: DropDownOption[];
  dropDownEnvironments?: DropDownOption[];

  selectedServiceId?: string;
  selectedEnvironment?: string;

  pinned: Pinned[] = [];

  boards: EnvironmentBoard[] = [];

  constructor(private _serviceCatalogueService: ServiceCatalogueService) {
    this.pinned = _serviceCatalogueService.getPinnedBoards();
    this.populate();
  }

  public projectChanged(option: DropDownOption): void {
    this.selectedServiceId = option?.value;
    this.selectedEnvironment = undefined;

    const service = this.catalogueItems?.find(c => c.id === option.value);
    const environments = service?.deployments?.map(d => d.environment).filter((v, i, a) => a.indexOf(v) === i);
    this.dropDownEnvironments = environments?.map(e => ({ name: e, value: e }));
  }

  public environmentChanged(option: DropDownOption): void {
    this.selectedEnvironment = option?.value;
  }

  public addBoard(): void {
    const pinnedUpdate = [...this.pinned];
    pinnedUpdate.push({ serviceId: this.selectedServiceId, environment: this.selectedEnvironment });
    this.pinned = pinnedUpdate;

    this._serviceCatalogueService.setPinnedBoards(this.pinned);
    this.populateBoards();
  }

  public removeBoard(board: EnvironmentBoard): void {
    const removeIndex = this.pinned.findIndex(p => p.environment == board.environment && p.serviceId == board.serviceId);
    if (removeIndex > -1) {
      this.pinned == this.pinned.splice(removeIndex, 1);
      this._serviceCatalogueService.setPinnedBoards(this.pinned);
      this.populateBoards();
    }
  }

  private populate = () => {
    const filter: FilterOptions = { environment: "" };
    this._serviceCatalogueService.filter(filter).subscribe({
      next: (r) => {
        this.catalogueItems = [...r];

        const services = this.catalogueItems.filter(s => s.deployments);
        this.dropDownProjects = services.map(m => ({ name: m.name, value: m.id }));
        this.populateBoards();
      },
      error: (e) => {
        console.log('Oh no an error!', e);
      }
    });
  };

  private populateBoards() {
    const allBoards = this.transformToBoards(this.catalogueItems!);
    const updatedBoards = [];

    for (let i = 0; i < this.pinned.length; i++) {
      var pinnedBoard = this.pinned[i];
      var match = allBoards.find(b => b.serviceId === pinnedBoard.serviceId && b.environment == pinnedBoard.environment);
      if (match) {
        updatedBoards.push(match);
      }
    }
    this.boards = updatedBoards;
  }

  private transformToBoards(items: ServiceCatalogueItem[]): EnvironmentBoard[] {
    const myboards: EnvironmentBoard[] = [];

    for (let i = 0; i < items.length; i++) {
      const catalogueService = items[i];

      if (catalogueService.deployments) {

        const distincyEnvironments = catalogueService.deployments.map(d => d.environment).filter((v, i, a) => a.indexOf(v) === i);

        for (let j = 0; j < distincyEnvironments.length; j++) {

          const environment = distincyEnvironments[j];

          const lastDeployment = catalogueService.deployments
            .filter(d => d.environment === environment && d.when)[0];

          myboards.push({
            serviceId: catalogueService.id,
            serviceName: catalogueService.name,
            environment: lastDeployment.environment,
            branch: lastDeployment.branch,
            lastDeployed: lastDeployment.when,
            version: lastDeployment.version
          });
        }
      }
    }

    return myboards;
  }

}
