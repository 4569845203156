import { Component } from '@angular/core';
import {
  ServiceCatalogueItem,
  ServiceCatalogueItemOldId,
} from 'src/app/models/ServiceCatalogueItem';
import { AuthService } from 'src/app/services/auth.service';
import { ServiceCatalogueService } from 'src/app/services/service-catalogue.service';
import { environment } from 'src/environments/environment';
import * as jwt_decode from "jwt-decode";
import { FilterOptions } from '../../models/FilterOptions';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss'],
})
export class CatalogueComponent {
  catalogueItems: ServiceCatalogueItem[] | undefined;
  showEdit: boolean = false;
  editItem: ServiceCatalogueItemOldId | undefined;
  isNew: boolean = false;
  error: string | undefined = undefined;
  showLoader: boolean = false;
  addEditModalTitle: string = '';
  hasDeleteAccess: boolean = false;
  hasEditAccess: boolean = false;
  filterOptions: FilterOptions | undefined = undefined;
  filterValue: string | undefined;

  public code = `
stage('Update Service Catalogue') {
  steps {
      script {
          env.SC_ID = "MyServiceID"
          env.SC_NAME = "My Service"
          env.SC_TYPE = "API"
          env.SC_DESCRIPTION = "This is the descript for my service"
          env.SC_OWNER = "Core Squad"
          env.SC_LAST_DEPLOYED_VERSION = env.VERSION
          env.SC_ENVIRONMENT = env.ENVIRONMENT
          env.SC_BRANCH = env.BRANCH
          serviceCatalogue()
      }
  }
}
  `.trim();

  deleteItemId?: string = undefined;
  showDeleteCofirm: boolean = false;
  deleteMessage: string = '';

  swaggerUrl: string = '';
  constructor(private _serviceCatalogueService: ServiceCatalogueService, private _authService: AuthService) {
    if (!_authService.isLoggedIn()) {
      this.redirectToAuthRLogin();
    }
    else {
      this.refresh(this.filterOptions);
      this.swaggerUrl = `${environment.apiBaseUrl}/swagger`;
    }

    // Pull apart token to determine access
    const token = _authService.getToken();
    if (token) {
      const decoded = jwt_decode.jwtDecode(token) as any;
      const groups = decoded['cognito:groups'] as string[];
      this.hasDeleteAccess = groups.some(s => s == "cat-delete");
      this.hasEditAccess = groups.some(s => s == "cat-edit");
    }
  }

  refresh = (filter: FilterOptions | undefined) => {
    this.catalogueItems = undefined;
    this.showLoader = true;
    this.error = undefined;
    this.filterOptions = filter;
    this.filterValue = filter?.environment;

    const idToken = this._authService.getToken();

    this._serviceCatalogueService.filter(this.filterOptions).subscribe({
      next: (r) => {
        this.catalogueItems = [...r];
      },
      error: (e) => {
        console.log('Oh no an error!', e);

        // Unauthorised, so redirect to login
        if (e.status === 401) {
          this.redirectToAuthRLogin();
        }
        else {
          this.error = e.message;
          this.showLoader = false;
        }
      },
      complete: () => {
        this.showLoader = false;
      }
    });
  };

  newItem = () => {
    this.editItem = {};
    this.isNew = true;
    this.showEdit = true;
    this.addEditModalTitle = 'New Service';
  };

  cancel = () => {
    this.showEdit = false;
  };

  save = (item: ServiceCatalogueItemOldId) => {
    if (this.isNew) {
      this._serviceCatalogueService.create(item).subscribe({
        next: () => {
          this.refresh(this.filterOptions);
        },
      });
    } else {
      this._serviceCatalogueService.update(item).subscribe({
        next: () => {
          this.refresh(this.filterOptions);
        },
      });
    }

    this.showEdit = false;
  };

  delete = (id: string) => {
    this.deleteMessage = `Delete service catalogue: ${id}?`;
    this.showDeleteCofirm = true;
    this.deleteItemId = id;
  };

  deleteItem = () => {
    this._serviceCatalogueService.delete(this.deleteItemId!).subscribe({
      next: (r) => {
        this.refresh(this.filterOptions);
      },
    });
    this.deleteItemId = undefined;
    this.showDeleteCofirm = false;
  };

  cancelDeleteItem = () => {
    this.deleteItemId = undefined;
    this.showDeleteCofirm = false;
  };

  edit = (item: ServiceCatalogueItem) => {
    this.editItem = { ...item, oldId: item.id };
    this.showEdit = true;
    this.isNew = false;
    this.addEditModalTitle = 'Edit Service';
  };

  redirectToAuthRLogin = () => {
    const url = `${environment.authrBaseUrl}/login/${encodeURIComponent(btoa(environment.appId + ''))}`;
    window.location.replace(url);
  }
}
