import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CatalogueListComponent } from './components/catalogue-list/catalogue-list.component';
import { ServiceCatalogueService } from './services/service-catalogue.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoaderComponent } from './components/loader/loader.component';
import { ButtonComponent } from './components/button/button.component';
import { EditCatalogueComponent } from './components/edit-catalogue/edit-catalogue.component';
import { TextboxComponent } from './components/textbox/textbox.component';
import { CatalogueComponent } from './components/catalogue/catalogue.component';
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker.component';
import { DatePipe } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { CollapseBoxComponent } from './components/collapse-box/collapse-box.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MultiToggleComponent } from './multi-toggle/multi-toggle.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { EnvironmentBoardsComponent } from './components/environment-boards/environment-boards.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';

@NgModule({ declarations: [
        AppComponent,
        CatalogueListComponent,
        LoaderComponent,
        ButtonComponent,
        EditCatalogueComponent,
        TextboxComponent,
        CatalogueComponent,
        DatetimePickerComponent,
        ModalComponent,
        ConfirmModalComponent,
        CollapseBoxComponent,
        LoginComponent,
        LogoutComponent,
        MultiToggleComponent,
        NavBarComponent,
        EnvironmentBoardsComponent,
        DropDownComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule, AppRoutingModule], providers: [ServiceCatalogueService, DatePipe, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {}
