<header>
  <h2>Service Catalogue <span *ngIf="catalogueItems">({{ catalogueItems.length }})</span></h2>
  <app-button *ngIf="hasEditAccess" (onClick)="newItem()" label="New Service"></app-button>
</header>

<div>
  <p>
    View the API:
    <a target="_blank" href="{{ swaggerUrl }}">{{ swaggerUrl }}</a>
  </p>
</div>

<app-collapse-box title="Jenkins Integration Sample" content="{{code}}"></app-collapse-box>

<app-modal *ngIf="showEdit" (onClose)="cancel()" [title]="addEditModalTitle">
  <app-edit-catalogue
    [item]="editItem"
    (onCancel)="cancel()"
    (onSave)="save($event)"
  >
  </app-edit-catalogue>
</app-modal>

<app-confirm-modal
  *ngIf="showDeleteCofirm"
  title="Are you sure?"
  [message]="deleteMessage"
  (onCancel)="cancelDeleteItem()"
  (onOk)="deleteItem()"
></app-confirm-modal>

<app-loader *ngIf="showLoader"></app-loader>
<app-catalogue-list *ngIf="catalogueItems"
  [catalogueItems]="catalogueItems"
  [hasDeleteAccess]="hasDeleteAccess"
  [hasEditAccess]="hasEditAccess"
  (onDelete)="delete($event)"
  (onEdit)="edit($event)"
  (onRefresh)="refresh($event)"
  [filterOptions]="filterOptions"
  [filterValue]="filterValue"
></app-catalogue-list>

<!-- Need a component for this with some styling -->
<div *ngIf="error">
  <h2>An error occurred</h2>
  <p>{{error}}</p>
</div>
