import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthService
  ) { }

  ngOnInit() {
    const tempToken = this._route.snapshot.queryParamMap.get('t');

    this._authService.getTokenFromTemp(tempToken!).subscribe({
      next: (r) => {
        if (r.idToken) {
          this._authService.setToken(r.idToken);
          window.location.replace('/');
        }
        else {
          alert('Login failed.');
        }
      }
    });
  }
}
