<div class="shadow">
  <div class="modal">
    <header>
      <span>{{ title }}</span>
      <button (click)="close()">&times;</button>
    </header>
    <content>
      <ng-content></ng-content>
    </content>
  </div>
</div>