import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ServiceCatalogueItem } from 'src/app/models/ServiceCatalogueItem';
import { FilterOptions } from '../../models/FilterOptions';
import { MultiToggleOption } from 'src/app/multi-toggle/MultiToggleOption';

@Component({
  selector: 'app-catalogue-list',
  templateUrl: './catalogue-list.component.html',
  styleUrls: ['./catalogue-list.component.scss'],
})
export class CatalogueListComponent {
  @Input() catalogueItems: ServiceCatalogueItem[] | undefined;
  @Input() hasDeleteAccess: boolean = false;
  @Input() hasEditAccess: boolean = false;
  @Input() filterOptions: FilterOptions | undefined;
  @Input() filterValue: string | undefined;
  @Output() onRefresh: EventEmitter<FilterOptions> = new EventEmitter();
  @Output() onDelete: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEdit: EventEmitter<ServiceCatalogueItem> = new EventEmitter<ServiceCatalogueItem>();

  environmentOptions: MultiToggleOption[] = [
    { name: 'All', value: undefined }, 
    { name: 'Dev', value: "dev" }, 
    { name: 'QA', value: "qa" }, 
    { name: 'UAT', value: "uat" }, 
    { name: 'Prod', value: "prod" }
  ];

  constructor() { }

  filter = (filterEnvironment: MultiToggleOption) => {
    this.filterOptions = { environment: filterEnvironment.value };
    this.filterValue = filterEnvironment.value;
    this.refresh();
  }

  refresh = () => {
    this.onRefresh.emit(this.filterOptions);
  };

  edit = (item: ServiceCatalogueItem) => {
    this.onEdit.emit(item);
  };

  delete = (id: string) => {
    this.onDelete.emit(id);
  };
}
