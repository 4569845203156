import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
})
export class DatetimePickerComponent {
  foramt: string = 'dd/MM/yy - HH:mm a';

  @Input() datetime: Date | undefined;
  @Output() datetimeChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(private datePipe: DatePipe) {}

  onInputChange(event: Event) {
    const newValue = (event.target as HTMLInputElement).value;

    var parsedDate = this.datePipe.transform(newValue); //, this.foramt);
    if (parsedDate) this.datetime = new Date(parsedDate);

    // console.log("Pop", newValue, this.datetime);
    this.datetimeChange.emit(this.datetime);
  }
}
