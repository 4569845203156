import { Component, EventEmitter, Input, Output } from '@angular/core';

type ButtonStyles = 'primary' | 'secondary' | 'delete';
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() label: string = 'Label';
  @Input() type: ButtonStyles = 'primary';
  @Input() disabled: boolean = false;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  getClass = () => {
    if (this.disabled)
      return 'disabled';

    return this.type;
  };

  onButtonClick(): void {
    if (this.onClick && !this.disabled)
      this.onClick.emit();
  }
}
