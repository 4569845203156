<input
  *ngIf="type === 'Textbox'"
  placeholder="{{ placeholder }}"
  [value]="text ?? ''"
  (input)="onInputChange($event)"
/>

<textarea
  *ngIf="type === 'TextArea'"
  placeholder="{{ placeholder }}"
  (input)="onInputChange($event)"
  >{{ text }}</textarea>
