import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenResponse } from '../models/tokenResponse';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private _http: HttpClient) { }

  public isLoggedIn(): boolean {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  public getToken() {
    return localStorage.getItem('authToken');
  }

  public setToken(token: string) {
    localStorage.setItem('authToken', token);
  }

  public clearToken() {
    localStorage.removeItem('authToken');
  }

  public getTokenFromTemp(tempToken: string): Observable<TokenResponse> {
    // const req = {
    //   singleUseToken: tempToken
    // };
    return this._http.get<TokenResponse>(`${environment.apiBaseUrl}/api/services/get-token?singleUseToken=${tempToken}`);
  }
}
