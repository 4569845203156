<app-modal title="Are you sure?" (onClose)="cancel()">
  <p>{{ message }}</p>
  <div class="button-group">
    <app-button
      label="Cancel"
      type="secondary"
      (onClick)="cancel()"
    ></app-button>
    <app-button label="OK" (onClick)="ok()"></app-button>
  </div>
</app-modal>
