<div class="pin-form">
    Pin board:

    Service
    <app-drop-down [options]="dropDownProjects || []" (onSelectionChange)="projectChanged($event)"></app-drop-down>

    Environment
    <app-drop-down [options]="dropDownEnvironments || []"
        (onSelectionChange)="environmentChanged($event)"></app-drop-down>

    <app-button label="Add" [disabled]="((selectedServiceId && selectedEnvironment) ? false : true)"
        (onClick)="addBoard()"></app-button>
</div>

<div class="boards">
    <div class="board" *ngFor="let board of boards">
        <a class="unpin" (click)="removeBoard(board)"><span>Unpin</span></a>
        <h2>{{board.serviceName}}</h2>
        <div class="environment">{{board.environment}}</div>
        <div>{{board.lastDeployed | date: 'd/M/yyyy - h:mm aa'}}</div>
        <div>{{board.branch}}</div>
    </div>
</div>