import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collapse-box',
  templateUrl: './collapse-box.component.html',
  styleUrls: ['./collapse-box.component.scss']
})
export class CollapseBoxComponent {
  @Input() title: string = 'Title';
  @Input() content: string = '...';
  @Input() isCollapsed: boolean = true;

  public collapseClick = () => {
    this.isCollapsed = !this.isCollapsed;
  }
}
