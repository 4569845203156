import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TextDisplayType } from './TextDisplayType';

@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
})
export class TextboxComponent {
  @Input() text: string | undefined = '';
  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() placeholder: string = '';
  @Input() type: string = 'Textbox';

  onInputChange(event: Event) {
    const newValue = (event.target as HTMLInputElement).value;
    this.text = newValue;
    this.textChange.emit(this.text);
  }
}
