import { Component, EventEmitter, Input, Output } from '@angular/core';

export class DropDownOption {
  name?: string;
  value?: string
}

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent {
  @Input() options: DropDownOption[] = [];
  @Output() onSelectionChange = new EventEmitter<DropDownOption>();

  selectedOption?: DropDownOption;

  onSelect(option?: DropDownOption): void {
    this.selectedOption = option;

    if (this.onSelectionChange)
      this.onSelectionChange.emit(option);
  }
}
