import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ServiceCatalogueItem,
  ServiceCatalogueItemOldId,
} from 'src/app/models/ServiceCatalogueItem';

@Component({
  selector: 'app-edit-catalogue',
  templateUrl: './edit-catalogue.component.html',
  styleUrls: ['./edit-catalogue.component.scss'],
})
export class EditCatalogueComponent {
  @Input() item?: ServiceCatalogueItemOldId = undefined;
  @Output() onSave: EventEmitter<ServiceCatalogueItem> =
    new EventEmitter<ServiceCatalogueItem>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

  save = () => {
    this.onSave.emit(this.item);
  };

  cancel = () => {
    this.onCancel.emit();
  };
}
