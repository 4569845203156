<div class="form">
  <div class="horizontal-group">
    <div class="field">
      <label>ID</label>
      <app-textbox placeholder="eg. ABC1" [(text)]="item!.id"></app-textbox>
    </div>

    <div class="field">
      <label>Name</label>
      <app-textbox placeholder="eg. My App" [(text)]="item!.name"></app-textbox>
    </div>
  </div>

  <div class="field">
    <label>Description</label>
    <app-textbox
      placeholder="Describe the service"
      [(text)]="item!.description"
      type="TextArea"
    ></app-textbox>
  </div>

  <div class="horizontal-group">
    <div class="field">
      <label>Type</label>
      <app-textbox
        placeholder="eg. Web API"
        [(text)]="item!.type"
      ></app-textbox>
    </div>
    <div class="field">
      <label>Owner</label>
      <app-textbox
        placeholder="eg. Core Squad"
        [(text)]="item!.owner"
      ></app-textbox>
    </div>
  </div>

  <div *ngIf="item?.deployments" class="deployments">
    <table>
      <thead>
        <tr>
          <th>When</th>
          <th>Environment</th>
          <th>Version</th>
          <th>Branch</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let deployment of item?.deployments">
          <td>{{ deployment.when }}</td>
          <td>{{ deployment.environment }}</td>
          <td>{{ deployment.version }}</td>
          <td>{{ deployment.branch }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="button-group">
    <app-button
      label="Cancel"
      (onClick)="cancel()"
      type="secondary"
    ></app-button>
    <app-button label="Save" (onClick)="save()"></app-button>
  </div>
</div>
