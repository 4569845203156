<div class="table-controls">
  <app-multi-toggle [options]="environmentOptions" [value]="filterValue" (onChange)="filter($event)"></app-multi-toggle>
  <app-button label="Refresh" (onClick)="refresh()"></app-button>
</div>
<table *ngIf="catalogueItems">
  <thead>
    <tr>
      <th>ID</th>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th style="width: 100px;">Owner</th>
      <th style="width: 200px;">Last Deployed</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of catalogueItems">
      <td>{{ item.id }}</td>
      <td>{{ item.name }}</td>
      <td>{{ item.type }}</td>
      <td>{{ item.description }}</td>
      <td>{{ item.owner }}</td>
      <td class="deployments-cell">
        <div *ngIf="item.deployments != null && item.deployments.length > 0">
          <span>{{ item.deployments[0].when | date : "d/M/yy HH:mm" }}</span>
          <span class="tag environment">{{item.deployments[0].environment}}</span>
          <span class="version">{{ item.deployments[0].version }}</span>
          <span *ngIf="item.deployments[0].branch && item.deployments[0].branch != ''" class="tag branch">{{item.deployments[0].branch}}</span>
          <div class="deployment-list">
            <div *ngFor="let deployment of item.deployments.slice(0,5)">
              <span>{{ deployment.when | date : "d/M/yy HH:mm" }}</span>
              <span class="tag environment">{{deployment.environment}}</span>
              <span class="version">{{ deployment.version }}</span>
              <span *ngIf="deployment.branch && deployment.branch != ''" class="tag branch">{{deployment.branch}}</span>
            </div>
          </div>
        </div>
      </td>
      <td class="buttons">
        <app-button *ngIf="hasEditAccess" (onClick)="edit(item)" label="Edit"></app-button>
        <app-button *ngIf="hasDeleteAccess" (onClick)="delete(item.id ?? '')" label="Delete" type="delete"></app-button>
      </td>
    </tr>
  </tbody>
</table>
