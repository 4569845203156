<nav class="nav-bar">
    <div>
        <div class="main-links">
            <a href="/">Home</a>
            <a href="/environment-board">Environment Board</a>
        </div>
        <div class="right">
            <a href="/logout">Logout</a>
        </div>
    </div>
</nav>