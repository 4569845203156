import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() title: string = '';
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  close = () => {
    this.onClose.emit();
  };
}
