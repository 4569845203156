import { Injectable } from '@angular/core';
import {
  ServiceCatalogueItem,
  ServiceCatalogueItemOldId,
} from '../models/ServiceCatalogueItem';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { FilterOptions } from '../models/FilterOptions';
import { Pinned } from '../models/Pinned';

@Injectable({
  providedIn: 'root',
})
export class ServiceCatalogueService {
  constructor(private _http: HttpClient, private _authService: AuthService) { }

  options = { headers: this.generateHeaders() };

  public getAll(): Observable<ServiceCatalogueItem[]> {
    return this._http.get<ServiceCatalogueItem[]>(`${environment.apiBaseUrl}/api/services`, this.options);
  }

  public filter(filter: FilterOptions | undefined): Observable<ServiceCatalogueItem[]> {
    return this._http.get<ServiceCatalogueItem[]>(`${environment.apiBaseUrl}/api/services/filter?environment=${filter?.environment ?? ''}`, this.options);
  }

  public delete(id: string): Observable<any> {
    return this._http.delete(`${environment.apiBaseUrl}/api/services/${id}`, this.options);
  }

  public create(item: ServiceCatalogueItem): Observable<any> {
    return this._http.post<void>(`${environment.apiBaseUrl}/api/services`, item, this.options);
  }

  public update(item: ServiceCatalogueItemOldId): Observable<any> {
    return this._http.put<void>(`${environment.apiBaseUrl}/api/services/${item.oldId}`, item, this.options);
  }

  public getPinnedBoards(): Pinned[] {
    const pinnedBoards = localStorage.getItem('pinnedBoards');
    return pinnedBoards ? (JSON.parse(pinnedBoards) as Pinned[]) : [];
  }

  public setPinnedBoards(pinned: Pinned[]) {
    localStorage.setItem('pinnedBoards', JSON.stringify(pinned));
  }

  private generateHeaders(): HttpHeaders {
    const token = this._authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
  }
}
