import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MultiToggleOption } from './MultiToggleOption';

@Component({
  selector: 'app-multi-toggle',
  templateUrl: './multi-toggle.component.html',
  styleUrls: ['./multi-toggle.component.scss']
})
export class MultiToggleComponent {
  @Input() options: MultiToggleOption[] = [];
  @Input() value: string | undefined = undefined;
  @Output() onChange: EventEmitter<MultiToggleOption> = new EventEmitter<MultiToggleOption>();

  constructor() {
    console.log(`Value: ${this.value}`);
  }

  optionClick = (newOption: MultiToggleOption) => {
    if (this.onChange !== null && newOption.value !== this.value) {
      this.value = newOption.value;
      this.onChange.emit(newOption);
    }
  }
}
